<template>
  <div class="th-back-top" v-show="btnFlag" @click="backTop">
    <img class="bg-back2top2" :src="require('@a/backTop.png')"/>
  </div>
</template>
<script>
export default {
  name: "BackTop",
  data() {
    return {
      btnFlag: false,
    };
  },

  mounted() {
    window.addEventListener("scroll", this.scrollToTop);
  },
  destroyed() {
    window.removeEventListener("scroll", this.scrollToTop);
  },
  methods: {
    // 点击图片回到顶部方法，加计时器是为了过渡顺滑
    backTop() {
      const that = this;
      let timer = setInterval(() => {
        let ispeed = Math.floor(-that.scrollTop / 5);
        document.documentElement.scrollTop = document.body.scrollTop =
          that.scrollTop + ispeed;
        if (that.scrollTop === 0) {
          clearInterval(timer);
        }
      }, 16);
    },

    // 为了计算距离顶部的高度，当高度大于60显示回顶部图标，小于60则隐藏
    scrollToTop() {
      const that = this;
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      that.scrollTop = scrollTop;
      if (that.scrollTop > 60) {
        that.btnFlag = true;
      } else {
        that.btnFlag = false;
      }
    },
  },
};
</script>
<style scoped>
.th-back-top {
  position: fixed;
  right: 15px;
  bottom: 104px;
  height: 39px;
  width: 80px;
  z-index: 9;
  opacity: 0.8;
  text-align: right;
}
.bg-back2top2 {
  display: inline-block;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: #fff;
}
</style>